<template>
  <div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-5 col-md-8 py-8 py-xl-0">
        <div class="card shadow">
          <div class="card-body p-6">
            <div class="mb-4 text-center">
              <router-link :to="{name: 'Home'}" v-if="school.thumbnail">
                <img :src="school.thumbnail.link" class="mb-4 avatar-xxl rounded bg-white object-fit-cover" alt="">
              </router-link>
              <h1 class="mb-1 fw-bold">Quên mật khẩu</h1>
              <span>Bạn đã có tài khoản?&nbsp;
                 <router-link :to="{name: 'Login'}" class="ms-1">Đăng nhập</router-link>
              </span>
            </div>
            <form v-on:submit.prevent="forgotPassword" autocomplete="off" v-if="step === 'forgot_password'">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="identity" class="form-label">Email hoặc SĐT của bạn:</label>
                    <input type="text" v-model="identity" id="identity" class="form-control" name="identity" placeholder="Email hoặc SĐT của bạn"
                           required />
                  </div>
                </div>
              </div>
              <div>
                <!-- Button -->
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary" :disabled="disabled">
                    Lấy lại mật khẩu
                  </button>
                </div>
              </div>
            </form>

            <form v-on:submit.prevent="validateCode" autocomplete="off" v-if="step === 'validate_code'">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Nhập mã xác thực gửi trong email/sđt của bạn:</label>
                    <input type="text" v-model="code" id="code" class="form-control" name="code" placeholder="Mã xác thực"
                           required />
                  </div>
                </div>
              </div>
              <div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary" :disabled="disabled">
                    Xác thực mã
                  </button>
                  <button type="button" class="btn btn-outline-primary mt-3" v-on:click="step = 'forgot_password'">
                    Quay lại
                  </button>
                </div>
              </div>
            </form>

            <Modal id="messageModal" title="Quên mật khẩu" :type="type" :message="message" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Modal from "@/components/Modal";
import {saveToken} from "@/core/services/jwt.service";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'ForgotPassword',
  props: {
    school: Object
  },
  components: {
    Modal
  },
  data() {
    return {
      identity: "",
      code: "",
      message: "",
      disabled: false,
      type: "",
      step: "forgot_password"
    }
  },
  methods: {
    forgotPassword() {
      this.disabled = true;

      let query = `mutation($identity: String!) {
        forgotPassword(identity: $identity)
      }`;

      ApiService.graphql(query, {identity: this.identity})
          .then(({data}) => {
            if (data.data && data.data.forgotPassword) {
              this.step = "validate_code";
              this.code = "";
              this.disabled = false;
            } else {
              this.disabled = false;
              this.message = data.errors[0].message;
              this.type = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.message = response.message;
            this.type = "danger";
            openModal("messageModal");
          });
    },
    validateCode() {
      this.disabled = true;

      let query = `mutation($identity: String!, $code: String!) {
        validateCode(identity: $identity, code: $code, type: "forgot_password")
      }`;

      ApiService.graphql(query, {identity: this.identity, code: this.code})
          .then(({data}) => {
            if (data.data && data.data.validateCode) {
              saveToken(data.data.validateCode);
              this.$router.push({name: "ChangePassword"});
            } else {
              this.disabled = false;
              this.message = data.errors[0].message;
              this.type = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.message = response.message;
            this.type = "danger";
            openModal("messageModal");
          });
    }
  }
}
</script>
